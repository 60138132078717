@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600;700&display=swap');


:root {
    --font-family-primary: 'Fira Sans', sans-serif;
  
    --color-text: #FCFCFC;

    --font-size-h1: 36px;
    --font-size-h2: 24px;
    --font-size-h3: 18px;
    --font-size-p: 16px;
  }

  @layer base {
    h1 {
      font-size: var(--font-size-h1);
    }
    
    h2 {
      font-size: var(--font-size-h2);
    }
    
    h3 {
      font-size: var(--font-size-h3);
    }
    
    p {
      font-size: var(--font-size-p);
    }
  }

.transition-all {
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.rotate-45 {
  transform: rotate(45deg);
}

.-rotate-45 {
  transform: rotate(-45deg);
}